import * as O from 'fp-ts/lib/Option'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/pipeable'
/**
 * Returns a left value if the string is empty,
 * and a right if the string is not empty.
 */
export function nonEmpty(
  error: string,
  s: O.Option<string>
): E.Either<string[], string> {
  return O.isNone(s) ? E.left([error]) : E.right(s.value)
}

export function nonEmptyArray<T>(
  error: string,
  xs: O.Option<Array<T>>
): E.Either<string[], Array<T>> {
  return O.isNone(xs) || (O.isSome(xs) && xs.value.length < 1)
    ? E.left([error])
    : E.right(xs.value)
}

export function clientAuthNotConfirmed(
  error: string,
  a: O.Option<boolean>
): E.Either<string[], boolean> {
  return pipe(
    a,
    O.fold(
      () => E.left([error]),
      a => (a ? E.right(a) : E.left([error]))
    )
  )
}

export function nonEmptyIdealStartDate(
  error: string,
  s: O.Option<{ date: Date; formatted: string }>
): E.Either<string[], string> {
  return O.isNone(s) ? E.left([error]) : E.right(s.value.formatted)
}

export function matchingPws(
  error: string,
  s: O.Option<string>,
  s2: O.Option<string>
): E.Either<string[], string> {
  return O.isNone(s) || O.isNone(s2)
    ? E.left([error])
    : s.value === s2.value
    ? E.right(s.value)
    : E.left([error])
}

/**
 * Returns a left value if the string is not a valid date
 * right, with the parsed date if it is
 */
export function isDate(
  error: string,
  dateStr: string
): E.Either<string[], Date> {
  const date = Date.parse(dateStr)
  return isNaN(date) ? E.left([error]) : E.right(new Date(date))
}

/**
 * Returns a left value if the start date is not before the end date,
 * a right value of number if it is (the number represents the number
 * of milliseconds in between the dates).
 */
export function isBefore(
  error: string,
  start: Date,
  end: Date
): E.Either<string[], number> {
  const difference = end.getTime() - start.getTime()
  return difference > 0 ? E.right(difference) : E.left([error])
}

/**
 * Returns a left value if the value supplied is empty or not a valid email,
 * a right value of string if it is valid
 */
export function isValidEmail(
  error: string,
  s: O.Option<string>
): E.Either<string[], string> {
  return O.isSome(s) &&
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(s.value)
    ? E.right(s.value)
    : E.left([error])
}
