import React from 'react'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/Array'
import { fromCompare, ordString } from 'fp-ts/lib/Ord'
import { pipe } from 'fp-ts/lib/pipeable'

import axios from 'axios'
import { useQuery } from 'react-query'

import { newIO, noOpIO } from '../util/io'
import {
  isProductHidden,
  isSchoolOrCommunityCenter,
  isSummerCamp,
} from '../util/helpers'
import { Product } from '../pages/fit-kidz-4-fun-registration'

const ordProductsByName = fromCompare<Product>((a, b) => {
  return ordString.compare(a.productName, b.productName)
})

export const useProducts = () => {
  const [availablePlans, setAvailablePlans] = React.useState<
    O.Option<Array<Product>>
  >(O.none)
  const [availableSchools, setAvailableSchools] = React.useState<
    O.Option<Array<Product>>
  >(O.none)
  const [availableSummerCamps, setAvailableSummerCamps] = React.useState<
    O.Option<Array<Product>>
  >(O.none)

  const locationId =
    process.env.NODE_ENV === 'development'
      ? process.env.GATSBY_FAUNA_LOCATION_ID_SANDBOX
      : process.env.GATSBY_FAUNA_LOCATION_ID_PRODUCTION

  const { isFetching: isFetchingProducts, data: locationData } = useQuery({
    queryKey: `location-products`,
    queryFn: async () => {
      const { data } = await axios.post('/api/fetch-location-products', {
        locationId,
      })
      return data
    },
    config: {
      retry: 2,
      refetchOnWindowFocus: false,
    },
  })

  React.useEffect(() => {
    pipe(
      O.fromNullable(locationData?.products),
      O.fold(
        () => noOpIO,
        (xs: Array<Product>) =>
          newIO(() =>
            setAvailablePlans(
              O.some(
                pipe(xs, A.filter(isProductHidden), A.sort(ordProductsByName))
              )
            )
          )
      )
    )()
  }, [locationData, isFetchingProducts])

  React.useEffect(() => {
    pipe(
      availablePlans,
      O.fold(
        () => noOpIO,
        xs =>
          newIO(() => {
            setAvailableSchools(
              O.some(
                pipe(
                  xs,
                  A.filter(isSchoolOrCommunityCenter),
                  A.sort(ordProductsByName)
                )
              )
            )
            setAvailableSummerCamps(
              O.some(
                pipe(xs, A.filter(isSummerCamp), A.sort(ordProductsByName))
              )
            )
          })
      )
    )
  }, [availablePlans])

  return {
    availablePlans,
    availableSchools,
    availableSummerCamps,
    isFetchingProducts,
  }
}
