import React from 'react'
import * as queryString from 'query-string'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/Array'
import { pipe } from 'fp-ts/lib/pipeable'
import Select from 'react-select'
import { DatePicker } from 'react-rainbow-components'

import {
  CfPaymentForm,
  CF_PLAN,
} from '../components/payments/cortiz-fitness-payment-form'
import Layout from '../components/layout'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Location } from '../components/nav/nav.types'
import { newIO, noOpIO } from '../util/io'
import { isProductHidden } from '../util/helpers'
import { format, addDays } from 'date-fns'
import { useSpring, animated } from 'react-spring'
import {
  FamilyMemberModal,
  FamilyMember,
} from '../components/modals/family-member-form'

import { sequenceOptionsStruct } from '../util/applicatives'
import { useProducts } from '../hooks/useProducts'
import { flow } from 'fp-ts/lib/function'

export type Product = {
  _id: string
  productName: string
  productType: string
  productDescription?: string
  sessionCount?: number
  sessionDuration?: number
  weekDaysAvailable?: Array<string>
  isHiddenFromCustomers?: boolean
  imageUrl?: string
  productMeta: {
    _id: string
    paymentGatewayProductId: string
  }
  billingMeta: {
    _id: string
    cost: number
    billingFrequency: string
    clientFacingPriceDescription: string
    paymentGatewayPriceId: string
  }
  alternativePrices: {
    data?: Array<{
      _id: string
      cost: number
      billingFrequency: string
      clientFacingPriceDescription: string
      paymentGatewayPriceId: string
    }>
  }
}

export enum FK4F_PLAN {
  BASE = 'base',
  SEMESTER = 'semester',
  YEAR = 'year',
}

export enum FK4F_SCHOOL {
  EAGLE_CREEK_ELEMENTARY = 'Eagle Creek Elementary',
  JOHN_YOUNG_ELEMENTARY = 'John Young Elementary',
  STONE_LAKE_ELEMENTARY = 'John Young Elementary',
}

const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_PUBLISHABLE_KEY || '',
  {
    stripeAccount: process.env.GATSBY_CORTIZ_FITNESS_CONNECT_ACCOUNT_ID,
  }
)

export type DropDownOption = {
  value: string
  label: string
}

const Fk4fSignUp = ({ location }: { location: Location }) => {
  const [plan, setPlan] = React.useState<O.Option<string>>(O.none)

  const [schoolOptions, setSchoolOptions] = React.useState<
    O.Option<Array<DropDownOption>>
  >(O.none)
  const [selectedSchool, setSelectedSchool] = React.useState<
    O.Option<DropDownOption>
  >(O.none)
  const [timeSlotOptions, setTimeSlotOptions] = React.useState<
    O.Option<Array<DropDownOption & { billingFrequency: string }>>
  >(O.none)
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState<
    O.Option<DropDownOption & { billingFrequency: string }>
  >(O.none)
  const [selectedSchoolPlan, setSelectedSchoolPlan] = React.useState<
    O.Option<Product>
  >(O.none)

  const [idealStartDate1, setIdealStartDate1] = React.useState<
    O.Option<{ date: Date; formatted: string }>
  >(O.none)
  const [showModal, setShowModal] = React.useState(false)
  const [familyMembers, setFamilyMembers] = React.useState<
    O.Option<Array<FamilyMember>>
  >(O.none)
  const [familyAccountName, setFamilyAccountName] = React.useState<
    O.Option<string>
  >(O.none)
  const [queryParams, setQueryParams] = React.useState<O.Option<string>>(O.none)

  const { availableSchools, availableSummerCamps } = useProducts()

  React.useEffect(() => {
    setQueryParams(location.search ? O.some(location.search) : O.none)
  }, [location])

  React.useEffect(() => {
    pipe(
      queryParams,
      O.fold(
        () => newIO(() => setPlan(O.none)),
        params =>
          newIO(() => {
            const parsedParams = queryString.parse(params)
            pipe(
              parsedParams.type === 'summer-camp'
                ? availableSummerCamps
                : availableSchools,
              O.fold(
                () => noOpIO,
                plans =>
                  newIO(() => {
                    pipe(
                      plans,
                      A.findFirst(p => p._id === parsedParams.plan),
                      O.fold(
                        () => noOpIO,
                        p =>
                          newIO(() => {
                            setSelectedSchool(
                              O.some({
                                label: p.productName,
                                value: p._id,
                              })
                            )
                            setPlan(O.some(p.productName))
                          })
                      )
                    )
                  })
              )
            )()
          })
      )
    )()
  }, [queryParams, availableSchools, availableSummerCamps])

  React.useEffect(() => {
    pipe(
      queryParams,
      O.fold(
        () =>
          newIO(() => {
            pipe(
              availableSchools,
              O.fold(
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                () => {},
                xs =>
                  setSchoolOptions(
                    O.some(
                      xs.map(x => ({ value: x._id, label: x.productName }))
                    )
                  )
              )
            )
          }),
        params =>
          newIO(() => {
            const parsedParams = queryString.parse(params)
            pipe(
              parsedParams.type === 'summer-camp'
                ? availableSummerCamps
                : availableSchools,
              O.fold(
                () => noOpIO,
                xs =>
                  newIO(() =>
                    setSchoolOptions(
                      O.some(
                        xs.map(x => ({ value: x._id, label: x.productName }))
                      )
                    )
                  )
              )
            )()
          })
      )
    )()
  }, [queryParams, availableSchools, availableSummerCamps])

  React.useEffect(() => {
    pipe(
      sequenceOptionsStruct({
        location: selectedSchool,
        camps: availableSummerCamps,
      }),
      O.fold(
        () => noOpIO,
        ({ camps, location }) =>
          newIO(() => {
            pipe(
              camps,
              A.findFirst(s => s._id === location.value),
              O.fold(
                () => noOpIO,
                locationFound =>
                  newIO(() => {
                    pipe(
                      locationFound,
                      O.some,
                      O.map(c => c.alternativePrices.data),
                      O.chain(O.fromNullable),
                      O.map(
                        A.map(t => ({
                          label: t.clientFacingPriceDescription,
                          value: t._id,
                          billingFrequency: t.billingFrequency,
                        }))
                      ),
                      O.fold(
                        () =>
                          newIO(() =>
                            setTimeSlotOptions(
                              O.some([
                                {
                                  label:
                                    locationFound.billingMeta
                                      .clientFacingPriceDescription,
                                  value: locationFound.billingMeta._id,
                                  billingFrequency:
                                    locationFound.billingMeta.billingFrequency,
                                },
                              ])
                            )
                          ),
                        timeSlots =>
                          newIO(() => {
                            const availableTimeSlots = [
                              {
                                label:
                                  locationFound.billingMeta
                                    .clientFacingPriceDescription,
                                value: locationFound.billingMeta._id,
                                billingFrequency:
                                  locationFound.billingMeta.billingFrequency,
                              },
                              ...timeSlots,
                            ]
                            setTimeSlotOptions(O.some(availableTimeSlots))
                            pipe(
                              queryParams,
                              O.fold(
                                () => noOpIO,
                                params =>
                                  newIO(() => {
                                    const parsedParams = queryString.parse(
                                      params
                                    )
                                    pipe(
                                      availableTimeSlots,
                                      A.findFirst(
                                        t => t.value === parsedParams?.price
                                      ),
                                      O.fold(
                                        () => noOpIO,
                                        timeSlot =>
                                          newIO(() =>
                                            setSelectedTimeSlot(
                                              O.some(timeSlot)
                                            )
                                          )
                                      )
                                    )()
                                  })
                              )
                            )
                          })
                      )
                    )()
                  })
              )
            )()
          })
      )
    )()
  }, [queryParams, selectedSchool, availableSummerCamps])

  React.useEffect(() => {
    const queryParams = location.search ? O.some(location.search) : O.none
    pipe(
      sequenceOptionsStruct({
        selectedSchool,
        plans: pipe(
          queryParams,
          O.fold(
            () => availableSchools,
            params => {
              const parsedParams = queryString.parse(params)
              return parsedParams.type === 'summer-camp'
                ? availableSummerCamps
                : availableSchools
            }
          )
        ),
      }),
      O.fold(
        () => noOpIO,
        ({ selectedSchool, plans }) =>
          newIO(() => {
            setSelectedSchoolPlan(
              pipe(
                plans,
                A.findFirst(p => p._id === selectedSchool.value)
              )
            )
          })
      )
    )
  }, [selectedSchool])

  const isServiceOfferedForSelectedPlan = (predicate: boolean) =>
    predicate ? (
      <svg
        className="h-6 w-6 text-green-500"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    ) : (
      <svg
        className="h-4 w-4 text-gray-300 mt-1 ml-1"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
      </svg>
    )

  const ServicesPerPlan = () => {
    return (
      <div className="grid gap-4 grid-cols-2 mt-2 mx-4">
        <ul>
          <li className="flex items-start mb-4">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(true)}
              {/* {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  (p: string) =>
                    p.toLowerCase().includes('base') ||
                    p.toLowerCase().includes('community') ||
                    p.toLowerCase().includes('semester') ||
                    p.toLowerCase().includes('year')
                )(plan)
              )} */}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              Fun Fitness Activities
            </p>
          </li>
          {/* <li className="mt-4 flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  (p: string) =>
                    p.toLowerCase().includes('semester') ||
                    p.toLowerCase().includes('year')
                )(plan)
              )}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              Save{' '}
              {O.fold(
                () => '$20 over base plan',
                (p: string) =>
                  p.toLowerCase().includes('base') ||
                  p.toLowerCase().includes('community')
                    ? '$20 over base plan'
                    : '$70 over semester plan'
              )(plan)}{' '}
            </p>
          </li> */}
          <li className="mt-4 flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(true)}
              {/* {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  (p: string) =>
                    p.toLowerCase().includes('base') ||
                    p.toLowerCase().includes('community') ||
                    p.toLowerCase().includes('semester') ||
                    p.toLowerCase().includes('year')
                )(plan)
              )} */}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              Learn about nutrition
            </p>
          </li>
        </ul>
        <ul>
          <li className="flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(true)}
              {/* {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  (p: string) =>
                    p.toLowerCase().includes('base') ||
                    p.toLowerCase().includes('community') ||
                    p.toLowerCase().includes('semester') ||
                    p.toLowerCase().includes('year')
                )(plan)
              )} */}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              Email newsletter
            </p>
          </li>
          <li className="mt-4 flex items-start">
            <div className="flex-shrink-0">
              {isServiceOfferedForSelectedPlan(true)}
              {/* {isServiceOfferedForSelectedPlan(
                O.fold(
                  () => false,
                  (p: string) =>
                    p.toLowerCase().includes('semester') ||
                    p.toLowerCase().includes('year')
                )(plan)
              )} */}
            </div>
            <p className="ml-3 text-base leading-6 font-medium text-gray-400">
              All sports guaranteed to learn and play
            </p>
          </li>
          {/* {O.fold(
            () => null,
            p => (
              <li className="flex items-start my-4">
                <div className="flex-shrink-0">
                  {isServiceOfferedForSelectedPlan(
                    O.fold(
                      () => false,
                      (p: string) =>
                        p.toLowerCase().includes('base') ||
                        p.toLowerCase().includes('community') ||
                        p.toLowerCase().includes('semester') ||
                        p.toLowerCase().includes('year')
                    )(plan)
                  )}
                </div>
                <p className="ml-3 text-base leading-6 font-medium text-gray-400">
                  {pipe(
                    plan,
                    O.chain(
                      O.fromPredicate(
                        p =>
                          p.toLowerCase().includes('base') ||
                          p.toLowerCase().includes('community')
                      )
                    ),
                    O.fold(
                      () =>
                        pipe(
                          plan,
                          O.chain(
                            O.fromPredicate(p =>
                              p.toLowerCase().includes('semester')
                            )
                          ),
                          O.fold(
                            () =>
                              pipe(
                                plan,
                                O.chain(
                                  O.fromPredicate(p =>
                                    p.toLowerCase().includes('year')
                                  )
                                ),
                                O.fold(
                                  () => 'Month to month',
                                  _ => 'Full year (Sept-May)'
                                )
                              ),
                            semester =>
                              `Full semester (${
                                semester.toLowerCase().includes('fall')
                                  ? 'Fall Sept-Dec'
                                  : 'Spring Jan-May'
                              })`
                          )
                        ),
                      _ => 'Month to month'
                    )
                  )}
                </p>
              </li>
            )
          )(plan)} */}
        </ul>
      </div>
    )
  }

  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })

  const addFamilyMember = (familyMember: FamilyMember) =>
    pipe(
      familyMembers,
      O.fold(
        () => setFamilyMembers(O.some([familyMember])),
        existingFamilyMembers =>
          setFamilyMembers(O.some([...existingFamilyMembers, familyMember]))
      )
    )

  const removeFamilyMember = (index: number) =>
    pipe(
      familyMembers,
      O.fold(
        () => noOpIO,
        existingFamilyMembers =>
          newIO(() => {
            const updatedFamilyMembers = [...existingFamilyMembers]
            updatedFamilyMembers.splice(index, 1)
            setFamilyMembers(O.some(updatedFamilyMembers))
          })
      )
    )()

  return (
    <Layout seoTitle="Fit Kidz 4 Fun Registration">
      <FamilyMemberModal
        isModalVisible={showModal}
        onSubmit={familyMember => addFamilyMember(familyMember)}
        onClose={() => setShowModal(false)}
      />
      <animated.div
        style={springOpacity}
        className="grid grid-cols-2 bg-gray-100"
      >
        <div className="col-span-2 md:col-span-1">
          <div className="my-8 mx-4 rounded-md">
            <span className="flex justify-center text-2xl text-gray-600">
              Register for Fit Kidz 4 Fun
            </span>
            <p className="text-base bg-gray-100 rounded-md shadow-md leading-6 font-medium text-gray-500 p-4">
              Take peace of mind knowing your children are safe while
              exercising, enjoying supervised activities, and learning about
              healthy food choices
            </p>

            <span className="mt-8 flex justify-center text-gray-500 text-lg">
              {pipe(
                plan,
                O.fold(
                  () => 'Select a registration plan',
                  _ => 'Services included'
                )
              )}
            </span>
            <ServicesPerPlan />
          </div>
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="my-8 mx-4 bg-primary-600 shadow-md p-4 rounded-md grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <span className="text-primary-100">Selected plan</span>
              <Select
                value={pipe(selectedSchool, O.toUndefined)}
                options={pipe(
                  schoolOptions,
                  O.getOrElse(() => [{ value: '', label: '' }])
                )}
                onChange={val => {
                  setSelectedTimeSlot(O.none)
                  setSelectedSchool(O.some(val))
                  setPlan(O.some(val.label))
                }}
                className="shadow-md w-auto z-10"
              />
              {pipe(
                selectedSchoolPlan,
                O.fold(
                  () => null,
                  s => (
                    <div className="mt-2">
                      <span className="text-primary-300 text-md">Plan</span>
                      <ul className="list-disc ml-6">
                        <li className="text-primary-300 text-sm">
                          {pipe(s, x => x.productName)}
                        </li>
                      </ul>
                      <span className="text-yellow-300 text-md mt-2">
                        Available Days
                      </span>
                      <ul className="list-disc ml-6 -mb-2">
                        {s?.weekDaysAvailable?.length &&
                          s?.weekDaysAvailable?.map((w, idx) => (
                            <li
                              key={w + idx}
                              className="text-yellow-300 text-sm"
                            >
                              {w}
                            </li>
                          ))}
                      </ul>
                    </div>
                  )
                )
              )}
            </div>
            <div className="flex flex-col w-72">
              {pipe(
                timeSlotOptions,
                O.fold(
                  () => null,
                  timeSlots => (
                    <div>
                      <span className="text-primary-100">
                        Selected time slot
                      </span>
                      {pipe(
                        timeSlots,
                        A.map(t => (
                          <div
                            key={t.value}
                            className="inline-flex items-center"
                          >
                            <input
                              className="focus:ring-indigo-500 h-4 w-4 cursor-pointer"
                              style={{ appearance: 'revert' }}
                              type="radio"
                              id={t.value}
                              name="timeslot"
                              value={t.value}
                              onChange={() => setSelectedTimeSlot(O.some(t))}
                              checked={
                                pipe(
                                  selectedTimeSlot,
                                  O.map(s => s.value),
                                  O.getOrElse(() => '')
                                ) === t.value
                              }
                            />
                            <label
                              htmlFor={t.value}
                              className="ml-2 text-sm text-primary-100"
                            >
                              {t.label}
                            </label>
                            <br />
                          </div>
                        ))
                      )}
                    </div>
                  )
                )
              )}
            </div>
            <div className="col-span-2">
              <div className="flex flex-col">
                <div className="relative">
                  <span className="text-primary-100">Ideal start date</span>
                  <DatePicker
                    id="datePicker"
                    value={pipe(
                      idealStartDate1,
                      O.map(d => format(addDays(d.date, 1), 'yyyy-MM-dd')),
                      O.getOrElse(() => '')
                    )}
                    onChange={value => {
                      const formatted = format(value, 'yyyy-MM-dd')
                      setIdealStartDate1(
                        O.some({
                          date: value,
                          formatted,
                        })
                      )
                    }}
                    formatStyle="large"
                    locale="en-US"
                  />
                </div>
                <div className="col-span-2 mt-2">
                  <div className="flex flex-col">
                    <div className="relative">
                      <span className="text-primary-100">
                        Family account name
                      </span>
                    </div>
                    <input
                      id="familyName"
                      placeholder="enter family name..."
                      onChange={e =>
                        pipe(
                          O.fromNullable(e.target.value),
                          O.fold(
                            () => newIO(() => setFamilyAccountName(O.none)),
                            n =>
                              newIO(() => {
                                n === ''
                                  ? setFamilyAccountName(O.none)
                                  : setFamilyAccountName(O.some(n))
                              })
                          )
                        )()
                      }
                      className="form-input block w-full placeholder-gray-500 text-md text-cool-gray-800 sm:leading-5"
                    />
                  </div>
                </div>
                <div className="col-span-2 mt-2">
                  <div className="flex flex-col">
                    <div className="relative">
                      <span className="text-primary-100">Family member(s)</span>
                    </div>
                    <div className="grid grid-cols-2 space-x-1">
                      <button
                        type="button"
                        onClick={() => setShowModal(true)}
                        className="inline-flex w-40 h-8 items-center px-2.5 py-1.5 border border-gray-300 text-sm leading-4 font-medium rounded text-gray-600 bg-white hover:text-primary-400 hover:bg-primary-800 focus:outline-none focus:border-accent-300 focus:shadow-outline-orange active:text-secondary-400 active:bg-primary-800 shadow-sm transition ease-in-out duration-150"
                      >
                        Add Family Member
                      </button>
                      <div>
                        {pipe(
                          familyMembers,
                          O.fold(
                            () => null,
                            familyMembers_ =>
                              familyMembers_.map((familyMember, index) => (
                                <div
                                  key={index}
                                  className={`flex justify-between ${
                                    index > 0 ? 'py-1' : ''
                                  }`}
                                >
                                  <span
                                    className="border-b w-full"
                                    style={{
                                      color: '#02adc1',
                                      borderColor: '#02adc1',
                                    }}
                                  >
                                    {familyMember.name}
                                  </span>
                                  <button
                                    className="px-1 text-primary-200 text-opacity-75 hover:text-accent-400"
                                    onClick={() => removeFamilyMember(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="w-4 h-4"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z" />
                                    </svg>
                                  </button>
                                </div>
                              ))
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-8 col-span-2 md:col-span-1 md:col-start-2">
          <div className="mb-8">
            <label
              htmlFor="billing-details"
              className="ml-4 mb-2 block text-md font-medium leading-5 text-gray-500"
            >
              Billing details
            </label>
            <div id="billing-details">
              <Elements stripe={stripePromise}>
                <CfPaymentForm
                  plan={plan}
                  isCfPlan={false}
                  selectedSchoolPlan={selectedSchoolPlan}
                  selectedAlternativePrice={pipe(
                    // we only want this to be a O.some if they didn't select
                    // the original billingMeta._id in the options
                    sequenceOptionsStruct({
                      selectedPrice: selectedTimeSlot,
                      selectedPlan: selectedSchoolPlan,
                    }),
                    O.chain(
                      O.fromPredicate(
                        ({ selectedPlan, selectedPrice }) =>
                          selectedPlan.billingMeta._id !== selectedPrice.value
                      )
                    ),
                    O.map(({ selectedPrice }) => selectedPrice)
                  )}
                  familyMembers={familyMembers}
                  familyAccountName={familyAccountName}
                  idealStartDate1={idealStartDate1}
                  idealStartDate2={O.none}
                  idealStartDate3={O.none}
                  idealStartTime1={O.none}
                  idealStartTime2={O.none}
                  idealStartTime3={O.none}
                  idealDaysOfTheWeek={O.none}
                />
              </Elements>
            </div>
          </div>
        </div>
      </animated.div>
    </Layout>
  )
}

export default Fk4fSignUp
