/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react'
import * as queryString from 'query-string'
import * as O from 'fp-ts/lib/Option'
import * as E from 'fp-ts/lib/Either'
import * as A from 'fp-ts/lib/Array'
import * as TE from 'fp-ts/lib/TaskEither'
import { Do } from 'fp-ts-contrib/lib/Do'
import { pipe } from 'fp-ts/lib/pipeable'
import GoTrue from 'gotrue-js'

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'

import './payments-form.css'
import { newIO, noOpIO } from '../../util/io'
import {
  nonEmpty,
  matchingPws,
  nonEmptyIdealStartDate,
  clientAuthNotConfirmed,
  nonEmptyArray,
} from '../../util/validation'
import { safePost } from '../../util/safePost'
import { sequenceOptionsStruct } from '../../util/applicatives'
import { FamilyMember } from '../modals/family-member-form'
import {
  DropDownOption,
  Product,
} from '../../pages/fit-kidz-4-fun-registration'
import { Loader } from '../loader'
import { identity } from 'fp-ts/lib/function'

export enum CF_PLAN {
  SINGLE_SESSION = 'single_session',
  BASE = 'base',
  PREMIUM = 'premium',
  PLATINUM = 'platinum',
}

export enum FK4F_PLAN {
  EAGLE_CREEK_ELEMENTARY_BASE = 'eagle_creek_elementary_base',
  EAGLE_CREEK_ELEMENTARY_FALL_SEMESTER = 'eagle_creek_elementary_fall_semester',
  EAGLE_CREEK_ELEMENTARY_SPRING_SEMESTER = 'eagle_creek_elementary_spring_semester',
  EAGLE_CREEK_ELEMENTARY_YEAR = 'eagle_creek_elementary_year',
  JOHN_YOUNG_ELEMENTARY_BASE = 'john_young_elementary_base',
  JOHN_YOUNG_ELEMENTARY_FALL_SEMESTER = 'john_young_elementary_fall_semester',
  JOHN_YOUNG_ELEMENTARY_SPRING_SEMESTER = 'john_young_elementary_spring_semester',
  JOHN_YOUNG_ELEMENTARY_YEAR = 'john_young_elementary_year',
  WATERFORD_ELEMENTARY_BASE = 'waterford_elementary_base',
  WATERFORD_ELEMENTARY_FALL_SEMESTER = 'waterford_elementary_fall_semester',
  WATERFORD_ELEMENTARY_SPRING_SEMESTER = 'waterford_elementary_spring_semester',
  WATERFORD_ELEMENTARY_YEAR = 'waterford_elementary_year',
  FREEDOM_PARK_COMMUNITY_CENTER = 'freedom_park_community_center',
}

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#02a9bb',
      },
    },
    invalid: {
      iconColor: '#fcac81',
      color: '#fcac81',
    },
  },
}

const CardField = ({ onChange }: { onChange: () => void }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
)

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}: {
  label: string
  id: string
  type: string
  placeholder: string
  required: boolean
  autoComplete: string
  value: string | number | string[] | undefined
  onChange: () => void
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
)

const ErrorMessage = ({ children }: { children: React.ReactNode }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#007785"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#fcac81"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
)

type CfPaymentFormProps = {
  isCfPlan?: boolean
  familyMembers?: O.Option<Array<FamilyMember>>
  familyAccountName?: O.Option<string>
  selectedSchoolPlan?: O.Option<Product>
  selectedAlternativePrice: O.Option<
    DropDownOption & { billingFrequency: string }
  >
  plan: O.Option<string>
  idealStartDate1: O.Option<{ date: Date; formatted: string }>
  idealStartDate2: O.Option<{ date: Date; formatted: string }>
  idealStartDate3: O.Option<{ date: Date; formatted: string }>
  idealStartTime1: O.Option<string>
  idealStartTime2: O.Option<string>
  idealStartTime3: O.Option<string>
  idealDaysOfTheWeek: O.Option<Array<string>>
}

// required fields to submit
type CfPaymentForm = {
  name: O.Option<string>
  email: O.Option<string>
  phone: O.Option<string>
  plan: O.Option<string>
  idealStartDate1: O.Option<{ date: Date; formatted: string }>
  idealStartTime1?: O.Option<string>
  pw: O.Option<string>
  pwConfirmation: O.Option<string>
}

type ValidatedCfPaymentForm = {
  productId: string
  selectedPlan: string
  name: string
  email: string
  phone: string
  password: string
  idealStartDate: Array<string | null>
  idealDaysOfTheWeek?: Array<string>
  familyMembers?: Array<FamilyMember>
  familyAccountName?: string
}

type StripeCustomer = {
  id: string
}

type StripePaymentMethod = {
  id: string
  card: {
    exp_month?: number
    exp_year?: number
    brand?: string
    last4?: string
  }
}

type StripeSetupIntent = {
  id: string
  client_secret?: string
  description?: string
}

export const CfPaymentForm = (props: CfPaymentFormProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)
  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [emailOpt, setEmailOpt] = useState<O.Option<string>>(O.none)
  const [phoneOpt, setPhoneOpt] = useState<O.Option<string>>(O.none)
  const [nameOpt, setNameOpt] = useState<O.Option<string>>(O.none)
  const [clientAuthorization, setClientAuthorization] = useState<
    O.Option<boolean>
  >(O.none)
  const [pw, setPw] = useState<O.Option<string>>(O.none)
  const [pwConfirmation, setPwConfirmation] = useState<O.Option<string>>(O.none)
  const [promoCode, setPromoCode] = useState<O.Option<string>>(O.none)
  // if 3D secure requires action, we'll store the previously created stripe customer here
  const [
    previouslyStoredLeportailCustomerId,
    setPreviouslyStoredLeportailCustomerId,
  ] = useState<O.Option<string>>(O.none)
  const [previouslyStoredCustomer, setPreviouslyStoredCustomer] = useState<
    O.Option<StripeCustomer>
  >(O.none)
  const [
    previouslyStoredPaymentMethod,
    setPreviouslyStoredPaymentMethod,
  ] = useState<O.Option<StripePaymentMethod>>(O.none)
  const [
    previouslyStoredSetupIntent,
    setPreviouslyStoredSetupIntent,
  ] = useState<O.Option<StripeSetupIntent>>(O.none)

  const [validationErrors, setValidationErrors] = React.useState<
    O.Option<Array<string>>
  >(O.none)

  const [selectedFk4fPlan, setSelectedFk4fPlan] = useState<O.Option<Product>>(
    O.none
  )

  const auth = new GoTrue({
    APIUrl: `${process.env.GATSBY_LEPORTAIL_IDENTITY_URL}`,
    audience: '',
    setCookie: false,
  })

  React.useEffect(() => {
    pipe(
      O.fromNullable(props?.selectedSchoolPlan),
      O.flatten,
      O.fold(
        () => noOpIO,
        s =>
          newIO(() => {
            setSelectedFk4fPlan(O.some(s))
          })
      )
    )
  }, [props?.selectedSchoolPlan])

  const isOneTimePayment = (str: string) =>
    str === CF_PLAN.SINGLE_SESSION || str.includes('semester')

  const validateCfPaymentForm = (paymentForm: CfPaymentForm) =>
    Do(E.getValidation(A.getMonoid<string>()))
      .sequenceS({
        nameIsNotEmpty: nonEmpty('Name cannot be empty', paymentForm.name),
        pwIsNotEmpty: nonEmpty('Password cannot be empty', paymentForm.pw),
        pwConfirmationIsNotEmpty: nonEmpty(
          'Password confirmation cannot be empty',
          paymentForm.pwConfirmation
        ),
        pwsMatch: matchingPws(
          'Password and password confirmation do not match',
          paymentForm.pw,
          paymentForm.pwConfirmation
        ),
        emailIsValid: nonEmpty('Valid email required', paymentForm.email),
        phoneIsNotEmpty: nonEmpty('Phone cannot be empty', paymentForm.phone),
        planIsNotEmpty: nonEmpty(
          'Selected plan cannot be empty',
          paymentForm.plan
        ),
        idealStartDate1IsNotEmpty: nonEmptyIdealStartDate(
          'First ideal start date cannot be empty',
          paymentForm.idealStartDate1
        ),
        idealStartTime1IsNotEmpty: props?.isCfPlan
          ? nonEmpty(
              'First ideal start time cannot be empty',
              pipe(
                O.fromNullable(paymentForm.idealStartTime1),
                O.flatten,
                O.fold(
                  () => O.some(props?.isCfPlan ? '_' : ''),
                  t => O.some(t)
                )
              )
            )
          : E.right(''),
        familyAccountName: props?.isCfPlan
          ? E.right('')
          : nonEmpty(
              'Family account name required',
              pipe(O.fromNullable(props.familyAccountName), O.flatten)
            ),
        familyMembers: props?.isCfPlan
          ? E.right([''])
          : nonEmptyArray(
              'At least one attending family member required',
              pipe(O.fromNullable(props.familyMembers), O.flatten)
            ),
        clientAuthIsConfirmed: clientAuthNotConfirmed(
          'Authorization agreement is required',
          clientAuthorization
        ),
      })
      .return(res => ({
        plan: res.planIsNotEmpty,
        name: res.nameIsNotEmpty,
        email: res.emailIsValid,
        phone: res.phoneIsNotEmpty,
        idealStartDate1: `${res.idealStartDate1IsNotEmpty}${
          props?.isCfPlan ? '' : `+${res.idealStartTime1IsNotEmpty}`
        }`,
        password: res.pwsMatch,
        authConfirmed: res.clientAuthIsConfirmed,
        familyMembers: res.familyMembers,
        familyAccountName: res.familyAccountName,
      }))

  const handleSubmit = async (
    validatedCfPaymentForm: ValidatedCfPaymentForm
  ) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (cardComplete) {
      setProcessing(true)
    }

    // if validationErrors was previously filled, safe to remove them now that we passed validation
    setValidationErrors(O.none)

    Do(TE.taskEither)
      .bind(
        'stripePaymentMethod',
        // previouslyStoredPaymentMethod will be some if we're recovering from a failed 3D secure auth
        O.isNone(previouslyStoredPaymentMethod)
          ? TE.tryCatch(
              () =>
                stripe
                  .createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement) || { token: '' },
                    billing_details: {
                      email: validatedCfPaymentForm.email,
                      name: validatedCfPaymentForm.name,
                      phone: validatedCfPaymentForm.phone,
                    },
                  })
                  .then(res => res),
              err => err
            )
          : TE.taskEither.of({})
      )
      .bindL('lePortailStripeCustomer', () =>
        O.isNone(previouslyStoredCustomer)
          ? safePost({
              url: '/api/create-lePortail-stripe-customer',
              data: {
                email: validatedCfPaymentForm.email,
                name: validatedCfPaymentForm.name,
                phone: validatedCfPaymentForm.phone,
              },
            })
          : TE.taskEither.of({})
      )
      .bindL(
        'stripeCustomer',
        ({ stripePaymentMethod, lePortailStripeCustomer }) => {
          if (O.isNone(previouslyStoredLeportailCustomerId)) {
            setPreviouslyStoredLeportailCustomerId(
              O.some(lePortailStripeCustomer.id)
            )
          }
          return O.isNone(previouslyStoredCustomer)
            ? safePost({
                url: '/api/create-cortiz-fitness-stripe-customer',
                data: {
                  email: validatedCfPaymentForm.email,
                  name: validatedCfPaymentForm.name,
                  phone: validatedCfPaymentForm.phone,
                  paymentMethodId: stripePaymentMethod?.paymentMethod?.id,
                },
              })
            : TE.taskEither.of({})
        }
      )
      .bindL('setupIntent', ({ stripePaymentMethod, stripeCustomer }) => {
        if (O.isNone(previouslyStoredCustomer)) {
          setPreviouslyStoredCustomer(O.some(stripeCustomer))
        }
        if (O.isNone(previouslyStoredPaymentMethod)) {
          setPreviouslyStoredPaymentMethod(
            stripePaymentMethod?.paymentMethod
              ? O.some(stripePaymentMethod?.paymentMethod)
              : O.none
          )
        }

        return O.isNone(previouslyStoredSetupIntent)
          ? safePost({
              url: '/api/create-stripe-setup-intent',
              data: {
                customerId:
                  stripeCustomer?.id ??
                  pipe(
                    previouslyStoredCustomer,
                    O.map(c => c.id),
                    O.toUndefined
                  ),
                paymentMethodId: pipe(
                  previouslyStoredPaymentMethod,
                  O.map(p => p.id),
                  O.getOrElse(
                    () => stripePaymentMethod?.paymentMethod?.id ?? 'n/a'
                  )
                ),
                lePortailProductId: validatedCfPaymentForm.productId,
                description:
                  validatedCfPaymentForm.selectedPlan === CF_PLAN.SINGLE_SESSION
                    ? 'single 45min-55min workout session'
                    : validatedCfPaymentForm.selectedPlan.includes('fall')
                    ? 'Fit Kidz 4 Fun fall semester (Sept-Dec)'
                    : validatedCfPaymentForm.selectedPlan.includes('spring')
                    ? 'Fit Kidz 4 Fun spring semester (Jan-May)'
                    : validatedCfPaymentForm.selectedPlan.includes('year')
                    ? 'Fit Kidz 4 Fun annual plan (Sept-May)'
                    : validatedCfPaymentForm.selectedPlan.includes(
                        'summer camp'
                      )
                    ? 'Fit Kidz 4 Fun summer camp subscription'
                    : 'cortiz fitness subscription',
              },
            })
          : TE.taskEither.of({})
      })
      .bindL(
        'handledRequiredAction',
        ({ stripePaymentMethod, setupIntent }) => {
          if (
            setupIntent.status === 'requires_confirmation' ||
            setupIntent.status == 'requires_action' ||
            O.isSome(previouslyStoredSetupIntent)
          ) {
            return TE.tryCatch(
              () =>
                stripe
                  .confirmCardSetup(
                    pipe(
                      previouslyStoredSetupIntent,
                      O.map(s => s.client_secret),
                      O.getOrElse(() => setupIntent.client_secret)
                    ),
                    {
                      payment_method: pipe(
                        previouslyStoredPaymentMethod,
                        O.map(p => p.id),
                        O.getOrElse(() => stripePaymentMethod.paymentMethod.id)
                      ),
                    }
                  )
                  .then(res => res),
              err => err
            )
          } else {
            return TE.taskEither.of({})
          }
        }
      )
      .bindL('netlifyAuthUser', ({ setupIntent, handledRequiredAction }) => {
        if (
          setupIntent.status === 'succeeded' ||
          handledRequiredAction?.setupIntent?.status === 'succeeded'
        ) {
          return TE.tryCatch(
            () =>
              auth
                .signup(
                  validatedCfPaymentForm.email,
                  validatedCfPaymentForm.password,
                  {}
                )
                .then(res => res),
            err => err
          )
        } else {
          return TE.taskEither.throwError(
            handledRequiredAction?.setupIntent?.status
              ? { message: 'failed to set up payment method' }
              : {
                  message: 'failed to authenticate with card issuer',
                  setupIntent,
                }
          )
        }
      })
      .bindL(
        'storeCustomerInfo',
        ({
          lePortailStripeCustomer,
          stripePaymentMethod,
          stripeCustomer,
          setupIntent,
          handledRequiredAction,
          netlifyAuthUser,
        }) => {
          if (
            (isOneTimePayment(validatedCfPaymentForm.selectedPlan) &&
              (setupIntent.status === 'succeeded' ||
                handledRequiredAction?.setupIntent?.status === 'succeeded')) ||
            !isOneTimePayment(validatedCfPaymentForm.selectedPlan)
          ) {
            return safePost({
              url: '/api/store-customer-info',
              data: {
                idealDaysOfTheWeek:
                  validatedCfPaymentForm?.idealDaysOfTheWeek ?? undefined,
                paymentgatewayPaymentMethodId: pipe(
                  previouslyStoredPaymentMethod,
                  O.map(s => s.id),
                  O.getOrElse(
                    () => stripePaymentMethod?.paymentMethod?.id ?? undefined
                  )
                ),
                clientRequestedPromoCode: pipe(promoCode, O.toUndefined),
                selectedAlternativePriceId: pipe(
                  props.selectedAlternativePrice,
                  O.map(s => s.value),
                  O.toUndefined
                ),
                singleSessionOrSubscription:
                  validatedCfPaymentForm.selectedPlan === CF_PLAN.SINGLE_SESSION
                    ? 'workout session'
                    : validatedCfPaymentForm.selectedPlan.includes(
                        '- base plan'
                      ) ||
                      validatedCfPaymentForm.selectedPlan.includes(
                        'community'
                      ) ||
                      validatedCfPaymentForm.selectedPlan.includes('year')
                    ? 'fk4f subscription'
                    : validatedCfPaymentForm.selectedPlan.includes(
                        'semester'
                      ) ||
                      pipe(
                        props.selectedAlternativePrice,
                        O.exists(s => s.billingFrequency === 'ONE_TIME')
                      )
                    ? 'fk4f scheduled transaction'
                    : validatedCfPaymentForm.selectedPlan.includes('camp') &&
                      pipe(
                        props.selectedAlternativePrice,
                        O.exists(s => s.billingFrequency !== 'ONE_TIME')
                      )
                    ? 'summer camp subscription'
                    : 'cf subscription',
                setupIntentId: pipe(
                  previouslyStoredSetupIntent,
                  O.map(s => s.id),
                  O.getOrElse(() => setupIntent?.id)
                ),
                familyMembers: validatedCfPaymentForm?.familyMembers,
                familyAccountName: validatedCfPaymentForm?.familyAccountName,
                setupIntentClientSecret: pipe(
                  previouslyStoredSetupIntent,
                  O.map(s => s.client_secret),
                  O.getOrElse(() => setupIntent?.client_secret)
                ),
                setupIntentDescription: pipe(
                  previouslyStoredSetupIntent,
                  O.map(s => s.description),
                  O.getOrElse(() => setupIntent?.description)
                ),
                expMonth: pipe(
                  previouslyStoredPaymentMethod,
                  O.map(s => s.card?.exp_month),
                  O.getOrElse(
                    () => stripePaymentMethod?.paymentMethod?.card?.exp_month
                  )
                ),
                expYear: pipe(
                  previouslyStoredPaymentMethod,
                  O.map(s => s.card?.exp_year),
                  O.getOrElse(
                    () => stripePaymentMethod?.paymentMethod?.card?.exp_year
                  )
                ),
                cardBrand: pipe(
                  previouslyStoredPaymentMethod,
                  O.map(s => s.card?.brand),
                  O.getOrElse(
                    () => stripePaymentMethod?.paymentMethod?.card?.brand
                  )
                ),
                idealStartDate: validatedCfPaymentForm.idealStartDate,
                last4: pipe(
                  previouslyStoredPaymentMethod,
                  O.map(s => s.card?.last4),
                  O.getOrElse(
                    () => stripePaymentMethod?.paymentMethod?.card?.last4
                  )
                ),
                lePortailStripeCustomerId: pipe(
                  previouslyStoredLeportailCustomerId,
                  O.getOrElse(() => lePortailStripeCustomer.id)
                ),
                paymentgatewayCustomerId:
                  stripeCustomer?.id ??
                  pipe(
                    previouslyStoredCustomer,
                    O.map(c => c.id),
                    O.toUndefined
                  ),
                productId: validatedCfPaymentForm.productId,
                email: validatedCfPaymentForm.email,
                phone: validatedCfPaymentForm.phone,
                name: validatedCfPaymentForm.name,
                authId: netlifyAuthUser.id,
              },
            })
          } else {
            return TE.taskEither.of({})
          }
        }
      )
      .bindL('sendClientEmail', () =>
        safePost({
          url: '/api/send-cf-user-welcome-email',
          data: {
            email: validatedCfPaymentForm.email,
            name: validatedCfPaymentForm.name,
            singleSessionOrSubscription:
              validatedCfPaymentForm.selectedPlan === CF_PLAN.SINGLE_SESSION
                ? 'workout session'
                : validatedCfPaymentForm.selectedPlan.includes('semester')
                ? 'Fit Kids 4 Fun semester plan'
                : validatedCfPaymentForm.selectedPlan.includes('year')
                ? 'Fit Kids 4 Fun annual plan'
                : validatedCfPaymentForm.selectedPlan.includes('- base plan') ||
                  validatedCfPaymentForm.selectedPlan.includes('community')
                ? 'Fit Kids 4 Fun month to month plan'
                : 'subscription',
          },
        })
      )
      // .bindL('sendCortizFitnessEmail', ({ storeCustomerInfo }) =>
      //   safePost({
      //     url: '/api/send-cf-alert-email',
      //     data: {
      //       email: validatedCfPaymentForm.email,
      //       name: validatedCfPaymentForm.name,
      //       singleSessionOrSubscription:
      //         validatedCfPaymentForm.selectedPlan === CF_PLAN.SINGLE_SESSION
      //           ? 'workout session'
      //           : validatedCfPaymentForm.selectedPlan.includes('semester')
      //           ? 'Fit Kids 4 Fun semester plan'
      //           : validatedCfPaymentForm.selectedPlan.includes('year')
      //           ? 'Fit Kids 4 Fun annual plan'
      //           : validatedCfPaymentForm.selectedPlan.includes('- base plan') ||
      //             validatedCfPaymentForm.selectedPlan.includes('community')
      //           ? 'Fit Kids 4 Fun month to month plan'
      //           : 'subscription',
      //       subscriptionId: !isOneTimePayment(
      //         validatedCfPaymentForm.selectedPlan
      //       )
      //         ? storeCustomerInfo.customer.paymentMethods.data[0].subscriptions
      //             .data[0]._id
      //         : undefined,
      //       scheduledTransactionId: isOneTimePayment(
      //         validatedCfPaymentForm.selectedPlan
      //       )
      //         ? storeCustomerInfo.customer.paymentMethods.data[0]
      //             .scheduledTransactions.data[0]._id
      //         : undefined,
      //     },
      //   })
      // )
      .return(identity)()
      .then(
        E.fold(
          err => {
            if (err?.message?.includes('failed to authenticate')) {
              setPreviouslyStoredSetupIntent(O.some(err.setupIntent))
            }
            setError({
              message: err?.message ?? 'failed to capture payment method',
            })
            setProcessing(false)
          },
          result => {
            setProcessing(false)
            pipe(
              previouslyStoredPaymentMethod,
              O.fold(
                () =>
                  newIO(() =>
                    setPaymentMethod(result.stripePaymentMethod.paymentMethod)
                  ),
                paymentMethod => newIO(() => setPaymentMethod(paymentMethod))
              )
            )()
          }
        )
      )
  }

  // todo - we don't need this switch.. You can just grab the id off the fetch response from the parent Component

  const matchCfPlanWithFaunaId = (plan: string) => {
    if (process.env.NODE_ENV === 'development') {
      switch (plan) {
        case CF_PLAN.SINGLE_SESSION:
          return '277867063690658304'
        case CF_PLAN.BASE:
          return '277867063749378560'
        case CF_PLAN.PREMIUM:
          return '277867063766155776'
        case CF_PLAN.PLATINUM:
          return '277867063782932992'
        default:
          return ''
      }
    } else {
      // production keys
      switch (plan) {
        case CF_PLAN.SINGLE_SESSION:
          return '272681276146713106'
        case CF_PLAN.BASE:
          return '272682705200611840'
        case CF_PLAN.PREMIUM:
          return '272683270293946880'
        case CF_PLAN.PLATINUM:
          return '272683480218862099'
        default:
          return ''
      }
    }
  }

  const matchFk4fPlanWithFaunaId = (plan: string) => {
    if (process.env.NODE_ENV === 'development') {
      switch (plan) {
        case FK4F_PLAN.JOHN_YOUNG_ELEMENTARY_BASE:
          return '277867954506301954'
        case FK4F_PLAN.JOHN_YOUNG_ELEMENTARY_FALL_SEMESTER:
          return '277868902113870338'
        case FK4F_PLAN.JOHN_YOUNG_ELEMENTARY_SPRING_SEMESTER:
          return '277869039217279488'
        case FK4F_PLAN.JOHN_YOUNG_ELEMENTARY_YEAR:
          return '277868680920957442'
        case FK4F_PLAN.WATERFORD_ELEMENTARY_BASE:
          return '277868171313021442'
        case FK4F_PLAN.WATERFORD_ELEMENTARY_FALL_SEMESTER:
          return '277869286459965971'
        case FK4F_PLAN.WATERFORD_ELEMENTARY_SPRING_SEMESTER:
          return '277869189988876800'
        case FK4F_PLAN.WATERFORD_ELEMENTARY_YEAR:
          return '277868571582792192'
        case FK4F_PLAN.FREEDOM_PARK_COMMUNITY_CENTER:
          return '278228071488686595'
        default:
          return ''
      }
    } else {
      // production keys
      switch (plan) {
        case FK4F_PLAN.EAGLE_CREEK_ELEMENTARY_BASE:
          return '277755140282253826'
        case FK4F_PLAN.EAGLE_CREEK_ELEMENTARY_FALL_SEMESTER:
          return '277755403312300546'
        case FK4F_PLAN.EAGLE_CREEK_ELEMENTARY_SPRING_SEMESTER:
          return '277755644735390210'
        case FK4F_PLAN.EAGLE_CREEK_ELEMENTARY_YEAR:
          return '277778916395975168'
        default:
          return ''
      }
    }
  }

  const handleValidation = () =>
    pipe(
      validateCfPaymentForm({
        name: nameOpt,
        email: emailOpt,
        phone: phoneOpt,
        plan: props?.isCfPlan
          ? props.plan
          : pipe(
              selectedFk4fPlan,
              O.map(p => p.productName.toLowerCase())
            ),
        idealStartDate1: props.idealStartDate1,
        idealStartTime1: props?.isCfPlan ? undefined : props.idealStartTime1,
        pw,
        pwConfirmation,
      }),
      E.fold(
        errors =>
          newIO(() => {
            setValidationErrors(O.some(errors))
          }),
        validForm =>
          newIO(() => {
            const validFormPayload: ValidatedCfPaymentForm = {
              productId: props?.isCfPlan
                ? matchCfPlanWithFaunaId(validForm.plan)
                : pipe(
                    selectedFk4fPlan,
                    O.map(s => s._id),
                    O.getOrElse(() => '')
                  ),
              selectedPlan: validForm.plan,
              name: validForm.name,
              email: validForm.email,
              phone: validForm.phone,
              password: validForm.password,
              familyAccountName: pipe(
                O.fromNullable(props.familyAccountName),
                O.flatten,
                O.getOrElse(() => '')
              ),
              familyMembers: pipe(
                O.fromNullable(props.familyMembers),
                O.flatten,
                O.toUndefined
              ),
              idealDaysOfTheWeek: pipe(props.idealDaysOfTheWeek, O.toUndefined),
              idealStartDate: [
                props?.isCfPlan
                  ? `${validForm.idealStartDate1}+${pipe(
                      props.idealStartTime1,
                      O.getOrElse(() => '5:00 PM')
                    )}`
                  : `${validForm.idealStartDate1}5:00 PM`,
                pipe(
                  sequenceOptionsStruct({
                    date2: props.idealStartDate2,
                    time2: props.idealStartTime2,
                  }),
                  O.fold(
                    () => null,
                    ({ date2, time2 }) => `${date2.formatted}+${time2}`
                  )
                ),
                pipe(
                  sequenceOptionsStruct({
                    date3: props.idealStartDate3,
                    time3: props.idealStartTime3,
                  }),
                  O.fold(
                    () => null,
                    ({ date3, time3 }) => `${date3.formatted}+${time3}`
                  )
                ),
              ],
            }
            handleSubmit(validFormPayload)
          })
      )
    )()

  const submissionBtnText = () =>
    pipe(
      props?.isCfPlan
        ? props.plan
        : pipe(
            selectedFk4fPlan,
            O.map(p => p.productName)
          ),
      O.fold(
        () => 'Submit request',
        p =>
          p === CF_PLAN.SINGLE_SESSION
            ? 'Request one time $55 workout session'
            : p === CF_PLAN.BASE && props?.isCfPlan
            ? 'Request $360 /month subscription'
            : p === CF_PLAN.PREMIUM
            ? 'Request $480 /month subscription'
            : p === CF_PLAN.PLATINUM
            ? 'Request $600 /month subscription'
            : p.toLowerCase().includes('base') && !props?.isCfPlan
            ? 'Request month to month subscription'
            : p.toLowerCase().includes('community') && !props?.isCfPlan
            ? 'Request month to month subscription'
            : p.toLowerCase().includes('semester')
            ? 'Request semester plan'
            : p.toLowerCase().includes('year')
            ? 'Request annual plan'
            : p.toLowerCase().includes('summer camp')
            ? 'Request summer camp plan'
            : 'Submit request'
      )
    )

  return paymentMethod ? (
    <div className="Result bg-white rounded-md shadow-md mx-4 p-4">
      <h2 className="flex justify-center text-xl text-primary-600" role="alert">
        Request successfully sent 🎉
      </h2>
      <div className="text-gray-500">
        {`Thank you for your interest! You won't be charged until your start date
        has been finalized. We'll be following up with you shortly.`}
      </div>
    </div>
  ) : (
    <div className="Form">
      <fieldset className="FormGroup">
        <Field
          label="Name"
          id="name"
          type="text"
          placeholder="Jane Doe"
          required
          autoComplete="name"
          value={pipe(
            nameOpt,
            O.fold(
              () => '',
              n => n
            )
          )}
          onChange={e =>
            pipe(
              O.fromNullable(e.target.value),
              O.fold(
                () => newIO(() => setNameOpt(O.none)),
                n =>
                  newIO(() => {
                    n === '' ? setNameOpt(O.none) : setNameOpt(O.some(n))
                  })
              )
            )()
          }
        />
        <Field
          label="Email"
          id="email"
          type="email"
          placeholder="janedoe@gmail.com"
          required
          autoComplete="email"
          value={pipe(
            emailOpt,
            O.fold(
              () => '',
              n => n
            )
          )}
          onChange={e =>
            pipe(
              O.fromNullable(e.target.value),
              O.fold(
                () => newIO(() => setEmailOpt(O.none)),
                n =>
                  newIO(() => {
                    n === '' ? setEmailOpt(O.none) : setEmailOpt(O.some(n))
                  })
              )
            )()
          }
        />
        <Field
          label="Phone"
          id="phone"
          type="tel"
          placeholder="555-444-3333"
          required
          autoComplete="tel"
          value={pipe(
            phoneOpt,
            O.fold(
              () => '',
              n => n
            )
          )}
          onChange={e =>
            pipe(
              O.fromNullable(e.target.value),
              O.fold(
                () => newIO(() => setPhoneOpt(O.none)),
                n =>
                  newIO(() => {
                    n === '' ? setPhoneOpt(O.none) : setPhoneOpt(O.some(n))
                  })
              )
            )()
          }
        />
      </fieldset>
      <fieldset className="FormGroup">
        <CardField
          onChange={e => {
            setError(e.error)
            setCardComplete(e.complete)
          }}
        />
      </fieldset>
      {error && (
        <div className="my-2">
          <ErrorMessage>{error?.message ?? null}</ErrorMessage>
        </div>
      )}
      <div>
        <div className="mt-2 relative mx-4 flex flex-col">
          <div
            onKeyUp={event => {
              if (event.key === 'Enter') {
                // hack for react's broken onKeyPress for checkbox inputs
                document.activeElement.click()
              }
            }}
            className="mb-4 p-4 bg-gray-200 text-gray-600 text-sm rounded-md"
          >
            <label className="flex">
              <input
                type="checkbox"
                className="form-checkbox shadow mt-1"
                onClick={() =>
                  pipe(
                    clientAuthorization,
                    O.fold(
                      () => newIO(() => setClientAuthorization(O.some(true))),
                      clientAuth =>
                        newIO(() => setClientAuthorization(O.some(!clientAuth)))
                    )
                  )
                }
              />
              <div className="flex flex-col ml-2">
                <span>
                  I authorize Cortiz Fitness LLC to send instructions to the
                  financial institution that issued my card to take payments
                  from my card account.
                </span>
                {!props.isCfPlan && (
                  <>
                    <span className="mt-2">
                      I accept full responsibility for my child or
                      children&apos;s health and well-being. I acknowledge and
                      understand that no responsibility or liability is assumed
                      by Cortiz Fitness LLC and its representatives.
                    </span>
                    <span className="mt-2 text-accent-600">
                      During our sports and fitness program, every effort will
                      be made to assure your child or children&apos;s safety.
                      However, as with any other sports and fitness program,
                      there are risks involved. Your child may fall, get bumped
                      or scratched, injured or incur other issues.
                    </span>
                  </>
                )}
              </div>
            </label>
          </div>
          <div className="mb-4 p-4 bg-yellow-50 text-yellow-800 text-sm rounded-md shadow-lg">
            your credit card <strong>will not be charged until</strong> Cortiz
            Fitness LLC confirms the request
          </div>
          <div className="mb-4 p-4 bg-gray-200 text-gray-600 text-sm rounded-md">
            <strong>Enter a password</strong> so that you&apos;ll be able to
            conveniently manage your subscription, request additional services,
            and more. <strong>Be sure to remember this password</strong>, so
            that you&apos;ll be able to log into your client portal.
          </div>
          <div className="flex justify-between items-baseline">
            <input
              id="pw"
              onChange={e =>
                pipe(
                  O.fromNullable(e.target.value),
                  O.fold(
                    () => newIO(() => setPw(O.none)),
                    p =>
                      newIO(() => {
                        p === '' ? setPw(O.none) : setPw(O.some(p))
                      })
                  )
                )()
              }
              type="password"
              className="form-input block w-full sm:text-sm sm:leading-5 shadow-sm mr-2"
              placeholder="enter password"
            />
            <div className="px-2">
              {pipe(
                pw,
                O.fold(
                  () => (
                    <svg
                      className="h-4 w-4 text-gray-400"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M12 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v3h2v-3c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-4v14h18v-14h-12z" />
                    </svg>
                  ),
                  _ => (
                    <svg
                      className="h-4 w-4 text-primary-600"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10-4c0-2.206 1.794-4 4-4 2.205 0 4 1.794 4 4v4h-8v-4zm3.408 14l-2.842-2.756 1.172-1.173 1.67 1.583 3.564-3.654 1.174 1.173-4.738 4.827z" />
                    </svg>
                  )
                )
              )}
            </div>
          </div>
          <div className="mt-2 flex justify-between items-baseline">
            <input
              id="pw2"
              type="password"
              onChange={e =>
                pipe(
                  O.fromNullable(e.target.value),
                  O.fold(
                    () => newIO(() => setPwConfirmation(O.none)),
                    p2 =>
                      newIO(() => {
                        p2 === ''
                          ? setPwConfirmation(O.none)
                          : setPwConfirmation(O.some(p2))
                      })
                  )
                )()
              }
              className="form-input block w-full sm:text-sm sm:leading-5 shadow-sm mr-2"
              placeholder="confirm password"
            />
            <div className="px-2">
              {pipe(
                pwConfirmation,
                O.fold(
                  () => (
                    <svg
                      className="h-4 w-4 text-gray-400"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M12 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v3h2v-3c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-4v14h18v-14h-12z" />
                    </svg>
                  ),
                  pw2 =>
                    pw2 !== pipe(pw, O.toNullable) ? (
                      <svg
                        className="h-4 w-4 text-red-500"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-4.138 9.975l-1.862-1.836-1.835 1.861-1.13-1.129 1.827-1.86-1.862-1.837 1.129-1.13 1.859 1.827 1.838-1.871 1.139 1.139-1.833 1.86 1.868 1.836-1.138 1.14zm-5.862-9.975v-4c0-2.206 1.795-4 4-4s4 1.794 4 4v4h-8z" />
                      </svg>
                    ) : (
                      <svg
                        className="h-4 w-4 text-primary-600"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10-4c0-2.206 1.794-4 4-4 2.205 0 4 1.794 4 4v4h-8v-4zm3.408 14l-2.842-2.756 1.172-1.173 1.67 1.583 3.564-3.654 1.174 1.173-4.738 4.827z" />
                      </svg>
                    )
                )
              )}
            </div>
          </div>
          <div className="mt-2.5 flex flex-col">
            <span className="text-gray-400 font-semibold text-sm leading-5">
              Have a promo code ?
            </span>
            <input
              id="promoCode"
              type="text"
              onChange={e =>
                pipe(
                  O.fromNullable(e.target.value),
                  O.fold(
                    () => newIO(() => setPromoCode(O.none)),
                    code => newIO(() => setPromoCode(O.some(code)))
                  )
                )()
              }
              className="form-input block w-full sm:text-sm sm:leading-5 shadow-sm mr-2"
              placeholder="enter it here"
            />
          </div>
        </div>
      </div>

      <button
        className={`SubmitButton ${
          error ? 'SubmitButton--error' : ''
        } my-4 mx-auto focus:outline-none focus:border-orange-700 focus:shadow-outline-orange active:bg-orange-700`}
        type="submit"
        onClick={() => handleValidation()}
        disabled={
          !stripe ||
          processing ||
          pipe(
            props?.isCfPlan
              ? props.plan
              : pipe(
                  selectedFk4fPlan,
                  O.map(p => p._id)
                ),
            O.fold(
              () => true,
              _ => false
            )
          )
        }
      >
        {processing ? 'Processing...' : submissionBtnText()}
      </button>
      {pipe(
        validationErrors,
        O.fold(
          () => null,
          xs => (
            <ul className="list-disc ml-10">
              {xs.map((x, idx) => (
                <li key={`${x}-${idx}`} className="text-gray-400">
                  {x}
                </li>
              ))}
            </ul>
          )
        )
      )}
      {processing && (
        <div className="flex">
          <Loader variant="leftSide" />
          <span className="text-sm text-secondary-400 font-medium">
            this may take a couple moments to process, don&apos;t close your tab
            until this completes.
          </span>
        </div>
      )}
    </div>
  )
}
