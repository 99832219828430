import axios from 'axios'
import * as TE from 'fp-ts/lib/TaskEither'

export const axiosPost = async <A>(url: string, data: A) => {
  const result = await axios({
    url,
    method: 'POST',
    data,
  })

  return result.data
}

export const safePost = <A>({ url, data }: { url: string; data: A }) =>
  TE.tryCatch(
    () => axiosPost(url, data),
    err => err.response.data
  )
