import React from 'react'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/pipeable'
import { Transition } from '@tailwindui/react'
import { newIO, noOpIO } from '../../util/io'
import { sequenceOptionsStruct } from '../../util/applicatives'
import { isNotNil } from '../../util/type-guards'

export type FamilyMember = {
  name: string
  age: number
  gender: string
  schoolName?: string
  allergies?: Array<string>
  healthIssues?: Array<string>
  behavioralIssues?: Array<string>
}

export type FamilyMemberModalProps = {
  isModalVisible: boolean
  onSubmit: (familyMember: FamilyMember) => void
  onClose: () => void
}

// additional inputs for behavioral issues
function BehavioralIssue({
  behavioralIssue,
  index,
  removeBehavioralIssue,
}: {
  behavioralIssue: string
  index: number
  removeBehavioralIssue: (index: number) => void
}) {
  return (
    <div className="py-2 relative w-full inline-flex justify-between">
      <div
        id={`behavioralIssue${index}`}
        className="form-input block rounded-md shadow-md sm:text-sm sm:leading-5 w-full"
      >
        {behavioralIssue}
      </div>
      <button
        className="px-1 text-gray-400 hover:text-gray-600"
        onClick={() => removeBehavioralIssue(index)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z" />
        </svg>
      </button>
    </div>
  )
}

// additional inputs for health issues
function HealthIssue({
  healthIssue,
  index,
  removeHealthIssue,
}: {
  healthIssue: string
  index: number
  removeHealthIssue: (index: number) => void
}) {
  return (
    <div className="py-2 relative w-full inline-flex justify-between">
      <div
        id={`healthIssue${index}`}
        className="form-input block rounded-md shadow-md sm:text-sm sm:leading-5 w-full"
      >
        {healthIssue}
      </div>
      <button
        className="px-1 text-gray-400 hover:text-gray-600"
        onClick={() => removeHealthIssue(index)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z" />
        </svg>
      </button>
    </div>
  )
}

// additional inputs for allergies
function Allergy({
  allergy,
  index,
  removeAllergy,
}: {
  allergy: string
  index: number
  removeAllergy: (index: number) => void
}) {
  return (
    <div className="py-2 relative w-full inline-flex justify-between">
      <div
        id={`allergies${index}`}
        className="form-input block rounded-md shadow-md sm:text-sm sm:leading-5 w-full"
      >
        {allergy}
      </div>
      <button
        className="px-1 text-gray-400 hover:text-gray-600"
        onClick={() => removeAllergy(index)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z" />
        </svg>
      </button>
    </div>
  )
}

function AllergyForm({
  addAllergy,
  forceAllergyCount,
}: {
  addAllergy: (a: string) => void

  forceAllergyCount: number
}) {
  const [value, setValue] = React.useState('')

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    if (!value) return
    addAllergy(value)
    setValue('')
  }

  React.useEffect(() => {
    if (forceAllergyCount > 1) {
      handleSubmit()
    }
  }, [forceAllergyCount])

  return (
    <form onSubmit={handleSubmit} className="py-2 w-full">
      <div className="relative rounded-md shadow-md">
        <input
          type="text"
          className="form-input block sm:text-sm sm:leading-5 w-full h-auto"
          value={value}
          placeholder="enter allergy..."
          onChange={e => setValue(e.target.value)}
        />
      </div>
    </form>
  )
}

function HealthIssueForm({
  addHealthIssue,
  forceHealthIssueCount,
}: {
  addHealthIssue: (a: string) => void
  forceHealthIssueCount: number
}) {
  const [value, setValue] = React.useState('')

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    if (!value) return
    addHealthIssue(value)
    setValue('')
  }

  React.useEffect(() => {
    if (forceHealthIssueCount > 1) {
      handleSubmit()
    }
  }, [forceHealthIssueCount])

  return (
    <form onSubmit={handleSubmit} className="py-2 w-full">
      <div className="relative rounded-md shadow-md">
        <input
          type="text"
          className="form-input block sm:text-sm sm:leading-5 w-full h-auto"
          value={value}
          placeholder="enter health issue..."
          onChange={e => setValue(e.target.value)}
        />
      </div>
    </form>
  )
}

function BehavioralIssueForm({
  addBehavioralIssue,
  forceBehavioralIssueCount,
}: {
  addBehavioralIssue: (a: string) => void
  forceBehavioralIssueCount: number
}) {
  const [value, setValue] = React.useState('')

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    if (!value) return
    addBehavioralIssue(value)
    setValue('')
  }

  React.useEffect(() => {
    if (forceBehavioralIssueCount > 1) {
      handleSubmit()
    }
  }, [forceBehavioralIssueCount])

  return (
    <form onSubmit={handleSubmit} className="py-2 w-full">
      <div className="relative rounded-md shadow-md">
        <input
          type="text"
          className="form-input block sm:text-sm sm:leading-5 w-full h-auto"
          value={value}
          placeholder="enter behavioral issue..."
          onChange={e => setValue(e.target.value)}
        />
      </div>
    </form>
  )
}

export const FamilyMemberModal = (props: FamilyMemberModalProps) => {
  const [name, setName] = React.useState<O.Option<string>>(O.none)
  const [age, setAge] = React.useState<O.Option<number>>(O.none)
  const [schoolName, setSchoolName] = React.useState<O.Option<string>>(O.none)
  const [gender, setGender] = React.useState<O.Option<string>>(O.none)

  const [allergies1, setAllergies1] = React.useState<O.Option<string>>(O.none)
  const [moreAllergies, setMoreAllergies] = React.useState<
    O.Option<Array<string>>
  >(O.none)
  const [forceAllergyCount, setForceAllergyCount] = React.useState(0)
  const [healthIssues1, setHealthIssues1] = React.useState<O.Option<string>>(
    O.none
  )
  const [moreHealthIssues, setMoreHealthIssues] = React.useState<
    O.Option<Array<string>>
  >(O.none)
  const [forceHealthIssueCount, setForceHealthIssueCount] = React.useState(0)

  const [behavioralIssues1, setBehavioralIssues1] = React.useState<
    O.Option<string>
  >(O.none)
  const [moreBehavioralIssues, setMoreBehavioralIssues] = React.useState<
    O.Option<Array<string>>
  >(O.none)
  const [
    forceBehavioralIssueCount,
    setForceBehavioralIssueCount,
  ] = React.useState(0)

  const [showRequiredAstrek, setShowRequiredAstrek] = React.useState(false)

  React.useEffect(() => {
    pipe(
      sequenceOptionsStruct({
        name,
        age,
        gender,
      }),
      O.fold(
        () => noOpIO,
        _ =>
          showRequiredAstrek
            ? newIO(() => setShowRequiredAstrek(false))
            : noOpIO
      )
    )
  }, [name, age, gender])

  const addAllergy = (allergy: string) =>
    pipe(
      moreAllergies,
      O.fold(
        () => setMoreAllergies(O.some([allergy])),
        existingAllergies =>
          setMoreAllergies(O.some([...existingAllergies, allergy]))
      )
    )

  const removeAllergy = (index: number) =>
    pipe(
      moreAllergies,
      O.fold(
        () => noOpIO,
        existingAllergies =>
          newIO(() => {
            const updatedAllergies = [...existingAllergies]
            updatedAllergies.splice(index, 1)
            setMoreAllergies(O.some(updatedAllergies))
          })
      )
    )()

  const addHealthIssue = (healthIssue: string) =>
    pipe(
      moreHealthIssues,
      O.fold(
        () => setMoreHealthIssues(O.some([healthIssue])),
        existingHealthIssues =>
          setMoreHealthIssues(O.some([...existingHealthIssues, healthIssue]))
      )
    )

  const addBehavioralIssue = (behavioralIssue: string) =>
    pipe(
      moreBehavioralIssues,
      O.fold(
        () => setMoreBehavioralIssues(O.some([behavioralIssue])),
        existingBehavioralIssues =>
          setMoreBehavioralIssues(
            O.some([...existingBehavioralIssues, behavioralIssue])
          )
      )
    )

  const removeHealthIssue = (index: number) =>
    pipe(
      moreHealthIssues,
      O.fold(
        () => noOpIO,
        existingHealthIssues =>
          newIO(() => {
            const updatedHealthIssues = [...existingHealthIssues]
            updatedHealthIssues.splice(index, 1)
            setMoreHealthIssues(O.some(updatedHealthIssues))
          })
      )
    )()

  const removeBehavioralIssue = (index: number) =>
    pipe(
      moreBehavioralIssues,
      O.fold(
        () => noOpIO,
        existingBehavioralIssues =>
          newIO(() => {
            const updatedBehavioralIssues = [...existingBehavioralIssues]
            updatedBehavioralIssues.splice(index, 1)
            setMoreBehavioralIssues(O.some(updatedBehavioralIssues))
          })
      )
    )()

  const onKeyDownAllergy = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      setForceAllergyCount(s => s + 1)
    }
  }

  const onKeyDownHealthIssue = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      setForceHealthIssueCount(s => s + 1)
    }
  }

  const onKeyDownBehavioralIssue = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      setForceBehavioralIssueCount(s => s + 1)
    }
  }

  const flushState = () => {
    setName(O.none)
    setSchoolName(O.none)
    setAge(O.none)
    setGender(O.none)
    setMoreAllergies(O.none)
    setMoreBehavioralIssues(O.none)
    setMoreHealthIssues(O.none)
    setHealthIssues1(O.none)
    setBehavioralIssues1(O.none)
    setAllergies1(O.none)
  }

  return (
    <Transition
      show={props.isModalVisible}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
          </div>
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className="inline-block align-bottom px-4 pt-5 pb-4 overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div>
                  <div className="mx-auto flex items-center justify-center h-14 w-14 rounded-full bg-primary-100 sm:h-10 sm:w-10">
                    <svg
                      className="h-6 w-6 text-primary-600"
                      fill="currentColor"
                      viewBox="0 0 26 26"
                    >
                      <path d="M11.978 17c-.948.011-1.529.896-1.978 1.608-.965 1.535-2.01 3.161-2.909 4.529-.312.475-.65.859-1.219.863-.57.004-1.052-.685-.777-1.265.731-1.539 3.905-7.077 3.905-11.735 0-1.613-.858-2-2-2h-4c-.552 0-1-.448-1-1s.448-1 1-1h18c.553 0 1 .449 1 1 0 .551-.447 1-1 1h-4c-1.141 0-2 .387-2 2 0 4.658 3.175 10.196 3.906 11.735.275.58-.207 1.269-.777 1.265-.569-.004-.908-.388-1.219-.863-.899-1.368-1.944-2.994-2.91-4.529-.451-.717-1.038-1.611-2-1.608h-.022zm.022-17c1.657 0 3 1.344 3 3s-1.343 3-3 3c-1.655 0-3-1.344-3-3s1.345-3 3-3" />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div className="mt-2 grid grid-cols-2 gap-2">
                      <div className="col-span-1">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Name{' '}
                          {showRequiredAstrek && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>
                        <div className="mt-1 relative rounded-md shadow-md">
                          <input
                            id="name"
                            className="form-input block w-full sm:text-sm sm:leading-5"
                            placeholder="Jane Doe"
                            onChange={e => setName(O.some(e.target.value))}
                          />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <label
                          htmlFor="age"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Age{' '}
                          {showRequiredAstrek && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>
                        <div className="mt-1 relative rounded-md shadow-md">
                          <input
                            id="age"
                            className="form-input block w-full sm:text-sm sm:leading-5"
                            placeholder="12"
                            onChange={e => setAge(O.some(+e.target.value))}
                          />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <label
                          htmlFor="gender"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Gender{' '}
                          {showRequiredAstrek && (
                            <span className="text-red-500">*</span>
                          )}
                        </label>
                        <div className="mt-1 flex rounded-md">
                          <label className="inline-flex items-center">
                            <input
                              type="radio"
                              className="shadow-md form-radio"
                              name="gender"
                              onClick={() => setGender(O.some('male'))}
                              value="male"
                            />
                            <span className="ml-2 text-sm text-gray-600">
                              Male
                            </span>
                          </label>
                          <label className="inline-flex items-center ml-6">
                            <input
                              type="radio"
                              className="shadow-md form-radio"
                              name="gender"
                              onClick={() => setGender(O.some('female'))}
                              value="female"
                            />
                            <span className="ml-2 text-sm text-gray-600">
                              Female
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-span-2">
                        <label
                          htmlFor="schoolName"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          School Name
                        </label>
                        <div className="mt-1 relative rounded-md shadow-md">
                          <input
                            id="schoolName"
                            className="form-input block w-full sm:text-sm sm:leading-5"
                            placeholder="Hawkins Middle School"
                            onChange={e =>
                              setSchoolName(O.some(e.target.value))
                            }
                          />
                        </div>
                      </div>
                      <div className="col-span-2">
                        <label
                          htmlFor="allergies1"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Allergies
                        </label>
                        <div className="mt-1 flex flex-col items-end">
                          <div className="relative rounded-md shadow-md w-full">
                            <input
                              id="allergies1"
                              className="form-input block sm:text-sm sm:leading-5 w-full"
                              onKeyDown={onKeyDownAllergy}
                              onChange={e =>
                                setAllergies1(O.some(e.target.value))
                              }
                            />
                          </div>
                          {pipe(
                            moreAllergies,
                            O.fold(
                              () => null,
                              xs =>
                                xs.map((allergy, index) => (
                                  <Allergy
                                    key={index}
                                    index={index}
                                    allergy={allergy}
                                    removeAllergy={removeAllergy}
                                  />
                                ))
                            )
                          )}
                          {forceAllergyCount > 0 && (
                            <AllergyForm
                              addAllergy={addAllergy}
                              forceAllergyCount={forceAllergyCount}
                            />
                          )}
                          {O.isSome(allergies1) && (
                            <div
                              className="inline-flex text-gray-400 hover:text-gray-600 w-24 px-2 text-xs cursor-pointer items-center py-2"
                              onClick={() => setForceAllergyCount(c => c + 1)}
                            >
                              <span className="px-1">add more</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 "
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2">
                        <label
                          htmlFor="healthIssues1"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Health issues
                        </label>
                        <div className="mt-1 flex flex-col items-end">
                          <div className="relative rounded-md shadow-md w-full">
                            <input
                              id="healthIssues1"
                              className="form-input block sm:text-sm sm:leading-5 w-full"
                              onKeyDown={onKeyDownHealthIssue}
                              onChange={e =>
                                setHealthIssues1(O.some(e.target.value))
                              }
                            />
                          </div>
                          {pipe(
                            moreHealthIssues,
                            O.fold(
                              () => null,
                              xs =>
                                xs.map((healthIssue, index) => (
                                  <HealthIssue
                                    key={index}
                                    index={index}
                                    healthIssue={healthIssue}
                                    removeHealthIssue={removeHealthIssue}
                                  />
                                ))
                            )
                          )}
                          {forceHealthIssueCount > 0 && (
                            <HealthIssueForm
                              addHealthIssue={addHealthIssue}
                              forceHealthIssueCount={forceHealthIssueCount}
                            />
                          )}
                          {O.isSome(healthIssues1) && (
                            <div
                              className="inline-flex text-gray-400 hover:text-gray-600 w-24 px-2 text-xs cursor-pointer items-center py-2"
                              onClick={() =>
                                setForceHealthIssueCount(c => c + 1)
                              }
                            >
                              <span className="px-1">add more</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 "
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2">
                        <label
                          htmlFor="behavioralIssues1"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          Behavioral issues
                        </label>
                        <div className="mt-1 flex flex-col items-end">
                          <div className="relative rounded-md shadow-md w-full">
                            <input
                              id="behavioralIssues1"
                              className="form-input block sm:text-sm sm:leading-5 w-full"
                              onKeyDown={onKeyDownBehavioralIssue}
                              onChange={e =>
                                setBehavioralIssues1(O.some(e.target.value))
                              }
                            />
                          </div>
                          {pipe(
                            moreBehavioralIssues,
                            O.fold(
                              () => null,
                              xs =>
                                xs.map((behavioralIssue, index) => (
                                  <BehavioralIssue
                                    key={index}
                                    index={index}
                                    behavioralIssue={behavioralIssue}
                                    removeBehavioralIssue={
                                      removeBehavioralIssue
                                    }
                                  />
                                ))
                            )
                          )}
                          {forceBehavioralIssueCount > 0 && (
                            <BehavioralIssueForm
                              addBehavioralIssue={addBehavioralIssue}
                              forceBehavioralIssueCount={
                                forceBehavioralIssueCount
                              }
                            />
                          )}
                          {O.isSome(behavioralIssues1) && (
                            <div
                              className="inline-flex text-gray-400 hover:text-gray-600 w-24 px-2 text-xs cursor-pointer items-center py-2"
                              onClick={() =>
                                setForceBehavioralIssueCount(c => c + 1)
                              }
                            >
                              <span className="px-1">add more</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 "
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <button
                    type="button"
                    onClick={() => {
                      pipe(
                        sequenceOptionsStruct({
                          age,
                          name,
                          gender,
                        }),
                        O.fold(
                          () => newIO(() => setShowRequiredAstrek(true)),
                          res =>
                            newIO(() => {
                              props.onSubmit({
                                age: res.age,
                                name: res.name,
                                gender: res.gender,
                                schoolName: pipe(schoolName, O.toUndefined),
                                allergies: pipe(
                                  allergies1,
                                  O.fold(
                                    () => undefined,
                                    allergy =>
                                      allergy.trim() === 'none' ||
                                      allergy.trim() === 'nope' ||
                                      allergy.trim() === 'no'
                                        ? undefined
                                        : pipe(
                                            moreAllergies,
                                            O.fold(
                                              () => [allergy],
                                              additionalAllergies => [
                                                allergy,
                                                ...additionalAllergies,
                                              ]
                                            )
                                          )
                                  )
                                ),
                                healthIssues: pipe(
                                  healthIssues1,
                                  O.fold(
                                    () => undefined,
                                    healthIssue =>
                                      healthIssue.trim() === 'none' ||
                                      healthIssue.trim() === 'nope' ||
                                      healthIssue.trim() === 'no'
                                        ? undefined
                                        : pipe(
                                            moreHealthIssues,
                                            O.fold(
                                              () => [healthIssue],
                                              additionalHealthIssues => [
                                                healthIssue,
                                                ...additionalHealthIssues,
                                              ]
                                            )
                                          )
                                  )
                                ),
                                behavioralIssues: pipe(
                                  behavioralIssues1,
                                  O.fold(
                                    () => undefined,
                                    behavioralIssue =>
                                      behavioralIssue.trim() === 'none' ||
                                      behavioralIssue.trim() === 'nope' ||
                                      behavioralIssue.trim() === 'no'
                                        ? undefined
                                        : pipe(
                                            moreBehavioralIssues,
                                            O.fold(
                                              () => [behavioralIssue],
                                              additionalBehavioralIssues => [
                                                behavioralIssue,
                                                ...additionalBehavioralIssues,
                                              ]
                                            )
                                          )
                                  )
                                ),
                              })
                              flushState()
                              props.onClose()
                            })
                        )
                      )
                    }}
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-primary-600 text-base leading-6 font-medium text-white shadow-sm hover:text-primary-400 hover:bg-primary-800 focus:outline-none focus:border-accent-500 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    Save family member
                  </button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button
                    type="button"
                    onClick={() => props.onClose()}
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  )
}
