import { Product } from '../pages/fit-kidz-4-fun-registration'
import { pipe } from 'fp-ts/lib/pipeable'

export const isProductHidden = (product: Product) =>
  !product?.isHiddenFromCustomers ?? false

export const isSchoolOrCommunityCenter = (p: Product) =>
  p.productType === 'AFTER_SCHOOL_PROGRAM' ||
  p.productType === 'COMMUNITY_CENTER_PROGRAM'

export const isSummerCamp = (p: Product) => p.productType === 'SUMMER_CAMP'

export const parseMillitaryTime = (time: string) =>
  pipe(
    time,
    s => s.split(':'),
    time_ =>
      `${((Number(time_[0]) + 11) % 12) + 1}:${
        Number(time_[1]) < 10 ? `0${Number(time_[1])}` : `${Number(time_[1])}`
      } ${Number(time_[0]) > 11 ? 'pm' : 'am'}`
  )
